<template>
  <div id="loginBlock" class="align">
    <div class="contentBlock">
      <div class="title">
        <h3>Войти в личный кабинет</h3>
      </div>
      <div class="body">
        <div class="inputBlock">

          <input type="tel" name="login" id="login"
                 v-model.trim="PhoneNumber"
                 v-mask="'+7 (###) ###-##-##'"
                 v-on:focus="responseError = ''"
                 v-on:blur="checkLogin"
          >
          <label for="login" v-bind:class="{showLabel: PhoneNumber.length === 0}">Номер телефона</label>
          <div v-show="errorlogin.length > 0" v-html="errorlogin" class="errMsg"></div>
        </div>

        <div class="inputBlock">
          <label for="pass" v-bind:class="{showLabel: password.length === 0}">Пароль</label>
          <input type="password" name="password" id="pass"
                 v-model.trim="password"
                 v-on:focus="responseError = ''"
                 v-on:blur="checkPassword"
          >
          <div v-show="errorpassword.length > 0" v-html="errorpassword" class="errMsg"></div>
        </div>

        <div v-show="responseError.length > 0" v-html="responseError" class="errMsg"></div>
      </div>
      <div class="contentFooter">
        <div id="navLoginWrap">
          <router-link :class="'linkColor'" to="/login/recovery">Забыли пароль?</router-link><br/>
          <router-link :class="'linkColor'" to="/login/signin">Регистрация</router-link>
        </div>
        <button v-on:click="sendAction">Войти</button>
      </div>
    </div>
    <div class="greenBanner">
      <img src="@/assets/vector/greenBanner.svg" class="greenBanner">
    </div>

  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: "LoginForm",
    data() {
      return {
        password: '',
        errorlogin: '',
        errorpassword: '',
        responseError: '',
      }
    },
    methods: {
      checkPassword() {
        if (this.password.length < 6) {
          this.errorpassword = 'Некорреткный пароль';
          return false;
        } else {
          this.errorpassword = '';
          return true;
        }
      },
      checkLogin() {
        if (this.$bikeFunction.correctPhone(this.PhoneNumber)) {
          this.errorlogin = '';
          return true;
        } else {
          this.errorlogin = 'Некорректный логин';
          return false;
        }
      },
      checkRequriedFields() {
        let countErr = 0;
        if (!this.checkLogin()) {
          countErr += 1;
        }
        if (!this.checkPassword()) {
          countErr += 1;
        }
        return countErr === 0;
      },
      tryLogin(){
        localStorage.setItem('SessionKey',this.$kostili.uuidv4())
        this.$store.dispatch('setIsLoading', true);
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'login',
          PhoneNumber: this.PhoneNumber,
          PasswordHash: this.password
        }).then((res) => {
            this.$store.commit('setUserID', res.UserID);
            this.$store.commit('setSessionKey', res.SessionKey);
            this.$store.dispatch('setIsLoading', false);
            if (localStorage.getItem('redirect') != undefined) {
              this.$router.push(localStorage.getItem('redirect'));
            } else {
              this.$router.push('/');
            }
          })
          .catch((res) => {
            if ( res.errorCode > 0 || res.errorCode !== undefined ) {
              alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
            }
            try {
              this.responseError = res.errorMsg;
            } catch (e) {
              this.responseError = JSON.stringify(res);
            }
            this.$store.dispatch('setIsLoading', false);
          });
      },
      sendAction() {
        if (this.checkRequriedFields()) {
          this.tryLogin();
        }
      }
    },
    computed: {
      PhoneNumber: {
        get() {
          return this.loginVuex;
        },
        set(value) {
          this.$store.commit('setLogin', value)
        }
      },
      ...mapState({
        loginVuex: state => state.auth.PhoneNumber,
        passwordVuex: state => state.password,
      }),
    },
    mounted(){
      this.$myApi.checkVersion();
    }
  }
</script>

<style scoped>
@media screen and (min-width: 760px){
}
@media screen and (min-width: 1239px){
}

</style>
